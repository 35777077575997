import { CookieBar, getSettings, getSelectedBlockedUrls } from './cookies/cookie-bar'
import { BlockCookies } from './cookies/block-cookies'

function onRun() {
    if (getSettings()) {
        BlockCookies(getSelectedBlockedUrls())
    } else {
        BlockCookies(getAllBlockedUrls())
    }
    // runs code as the js is parsed and initialised
}

function init() {
    // runs code when the full document has loaded, not all resources
    CookieBar()
}

function getAllBlockedUrls() {
    const blockCookies = window.blockCookies || []

    return Array.from(blockCookies).map((data) => {
        return data.url
    })
}

onRun()

if (document.readyState !== 'loading') {
    init()
} else {
    document.addEventListener('DOMContentLoaded', init)
}
