let cookieUrlsToBlock

export function BlockCookies(urls = []) {
    cookieUrlsToBlock = urls

    const createElementBackup = document.createElement
    document.createElement = function (...args) {
        if (!(args[0].toLowerCase() === 'script' || args[0].toLowerCase() === 'iframe')) {
            return createElementBackup.bind(document)(...args)
        }

        const createdNode = createElementBackup.bind(document)(...args)

        const backupSetAttribute = createdNode.setAttribute.bind(createdNode)

        Object.defineProperties(createdNode, {
            src: {
                get() {
                    return createdNode.getAttribute('src')
                },
                set(value) {
                    if (
                        !createdNode.dataset.enableCookie &&
                        cookieUrlsToBlock.some((url) => value.match(new RegExp(url)))
                    ) {
                        if (createdNode.tagName === 'SCRIPT') {
                            backupSetAttribute('type', 'javascript/blocked')
                        }
                        backupSetAttribute('data-blocked-src', value)
                    } else {
                        backupSetAttribute('src', value)
                    }
                    return true
                }
            },
            type: {
                get() {
                    return createdNode.getAttribute('type')
                },
                set(value) {
                    let src = createdNode.src || createdNode.dataset.blockedSrc

                    if (
                        createdNode.tagName === 'SCRIPT' &&
                        !createdNode.dataset.enableCookie &&
                        src &&
                        cookieUrlsToBlock.some((url) => src.match(new RegExp(url)))
                    ) {
                        backupSetAttribute('data-blocked-type', value)
                        value = 'javascript/blocked'
                    }
                    backupSetAttribute('type', value)
                    return true
                }
            }
        })

        createdNode.setAttribute = function (name, value) {
            if (name === 'type' || name === 'src') {
                createdNode[name] = value
            } else {
                HTMLElement.prototype.setAttribute.call(createdNode, name, value)
            }
        }

        return createdNode
    }
}

export function enableBlockedScript(allowedUrls = []) {
    if (!allowedUrls.length) {
        return
    }

    cookieUrlsToBlock = cookieUrlsToBlock.filter((url) => !allowedUrls.includes(url))

    let els = document.querySelectorAll('[data-blocked-src]')
    Array.from(els).forEach((el) => {
        const src = el.dataset.blockedSrc

        if (cookieUrlsToBlock.some((url) => src.match(new RegExp(url)))) {
            return
        }

        const node = document.createElement(el.tagName)
        node.dataset.enableCookie = true
        node.src = src
        if (el.dataset.blockedType) {
            node.type = el.dataset.blockedType
        }
        el.replaceWith(node)
    })
}
