import Cookies from 'js-cookie/src/js.cookie'
import { transitionEnd } from '../animation'
import { enableBlockedScript } from './block-cookies'

let el
let settings
let cookieAcceptBtn
let cookieDeclineBtn
let cookieSettingsOpenBtn
let cookieSettingsCloseBtn
let cookieForm
const cookieName = 'cookie-settings'

export function CookieBar() {
    el = document.querySelector('.js-cookie')

    if (!el) {
        return
    }

    cookieForm = el.closest('form')

    if (Cookies.get(cookieName) !== undefined) {
        destroy()
        return
    }

    open()

    settings = el.querySelector('.js-cookie-settings')
    cookieAcceptBtn = el.querySelectorAll('.js-cookie-accept')
    cookieDeclineBtn = el.querySelectorAll('.js-cookie-decline')
    cookieSettingsOpenBtn = el.querySelector('.js-cookie-settings-open')
    cookieSettingsCloseBtn = el.querySelector('.js-cookie-settings-close')

    document.addEventListener('keydown', function (evt) {
        if (evt.key !== 'Escape') {
            return
        }

        closeSettings()
    })

    if (cookieSettingsOpenBtn) {
        cookieSettingsOpenBtn.addEventListener('click', openSettings)
    }

    if (cookieSettingsCloseBtn) {
        cookieSettingsCloseBtn.addEventListener('click', closeSettings)
    }

    if (cookieDeclineBtn) {
        Array.from(cookieDeclineBtn).map((e) => {
            e.addEventListener('click', decline)
        })
    }

    if (cookieAcceptBtn) {
        Array.from(cookieAcceptBtn).map((e) => {
            e.addEventListener('click', accept)
        })
    }

    transitionEnd(settings).subscribe(() => {
        if (!settings.classList.contains('is-active')) {
            settings.hidden = true
        }
    })
}

function openSettings() {
    settings.hidden = false

    requestAnimationFrame(() => {
        requestAnimationFrame(() => {
            settings.classList.add('is-active')
            document.body.dataset.lock = true
            cookieSettingsCloseBtn.focus()
        })
    })
}

function closeSettings(evt) {
    if (evt) {
        evt.preventDefault()
    }

    settings.classList.remove('is-active')
    document.body.removeAttribute('data-lock')
    cookieSettingsOpenBtn.focus()
}

function accept(evt) {
    evt.preventDefault()
    const formData = Object.fromEntries(new FormData(cookieForm))
    saveSettings(formData)
}

function decline(evt) {
    evt.preventDefault()
    const formData = Object.fromEntries(new FormData(cookieForm))

    const declinedFormData = {}

    Object.keys(formData).forEach((key) => {
        declinedFormData[key] = 0
    })

    saveSettings(declinedFormData)
}

function saveSettings(data) {
    Cookies.set(cookieName, JSON.stringify(data), { expires: 364 })
    enableBlockedScript(getAllowedUrls())
    close()
    closeSettings()
}

function open() {
    el.classList.add('is-active')
}

function close() {
    el.classList.remove('is-active')
    destroy()
}

function destroy() {
    setTimeout(function () {
        cookieForm.parentNode.removeChild(cookieForm)
    }, 5000)
}

export function getSettings() {
    const cookie = Cookies.get(cookieName)

    if (cookie) {
        return JSON.parse(cookie)
    }

    return false
}

export function getSelectedBlockedUrls() {
    const settings = getSettings()
    const blockCookies = window.blockCookies || []
    let blockedCookieUrls = []

    if (settings) {
        const allowedCategories = Object.keys(settings).filter(
            (category) => parseInt(settings[category]) !== 1
        )

        blockedCookieUrls = Array.from(blockCookies).filter((data) => {
            return allowedCategories.indexOf(data.category) !== -1
        })
    }

    return blockedCookieUrls.map((data) => {
        return data.url
    })
}

export function getAllowedUrls() {
    const settings = getSettings()
    const blockCookies = window.blockCookies || []
    let allowedUrls = []

    if (settings) {
        const allowedCategories = Object.keys(settings).filter(
            (category) => parseInt(settings[category]) === 1
        )

        allowedUrls = Array.from(blockCookies).filter((data) => {
            return allowedCategories.indexOf(data.category) !== -1
        })
    }

    return allowedUrls.map((data) => {
        return data.url
    })
}
